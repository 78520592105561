import React from 'react'
import { Helmet } from 'react-helmet'
import graphic from '../img/maintenance.png'

const title = 'Maintenance | Cardeon'
const url = 'https://cardeon.io/maintenance'
const desc = "The page you're looking for is under maintenance."

function Maintenance() {
    return (
        <div className="404 flex-c flex-grow-1 interior-view">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container">
                <div className="flex-c row">
                    <div className="flex-c col-12 align-items-center text-c pt-5">
                        <h1 className="mt-4">Under Maintenance</h1>
                        <p className=" mb-5 mt-1">We're working hard to make things awesome for you!</p>
                        <img
                            src={graphic}
                            title="Under Maintenance"
                            alt="Under Maintenance"
                            className="align-self-center img-centered"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Maintenance
