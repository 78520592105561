import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { appPlans } from '../config/slice/plans.slice'
import subsService from '../services/subs.service'

const title = 'Mental Health and Wellness | Cardeon'
const url = 'https://cardeon.io/'
const desc =
    'Cardeon is a data-driven, software company providing tools that help coaches scale, evolve and enhance.'

function Home() {
    const dispatch = useDispatch()
    const plans = useSelector(state => state.plans.value)

    useEffect(() => {
        async function init() {
            if (!plans) {
                const plans = await subsService.getPlans()
                dispatch(appPlans(plans))
            }
        }
        init()
    })

    return (
        <div className="flex-c">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc} />
                <link rel="canonical" href={url} />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={desc} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={desc} />
            </Helmet>
            <div className="flex-c container mb-5">
                <div className="flex-c row mt-md-5">
                    <div className="flex-c align-items-lg-center col-12">
                        <h2 className="flex-c h1-hero mt-5 mb-0">Innovative. Revolutionary. Empowering.</h2>
                        <h1 className="flex-c h2-sub mt-2 mb-4">Breakthrough solutions for mental health and wellness.</h1>
                    </div>
                </div>
                <div className="flex-c row align-items-lg-center mt-4 mb-5">
                    <div className="flex-c col-12 col-md-7 col-lg-5">
                        <div className="flex-c flex-sm-r flex-auto mb-md-5">
                            <Link
                                type="button"
                                className="btn btn-secondary mb-3 mb-sm-0 mr-sm-2"
                                title="Sign Up"
                                // to="/disclaimer"
                                to="/maintenance">
                                Get Started Free
                            </Link>
                            <Link
                                type="button"
                                className="btn btn-white ml-sm-2"
                                title="View Plans"
                                to="/pricing">
                                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex-c flex-md-r row my-5">
                    <div className="flex-c col-lg-4 mb-5">
                        <div className="flex-c bckg-240240245 border-r-16 mb-5 mr-lg-2">
                            <div className="flex-c px-4 pt-3 pb-4">
                                <div className="flex-r align-items-center justify-content-between mb-3">
                                    <span className="font-16 fw-700">Corrective</span>
                                    <span className="flex-c align-items-center justify-content-center circle-md bckg-215215225">
                                        <i className="fa-solid fa-notes-medical font-20"></i>
                                    </span>
                                </div>
                                <h3>Restorative Healing</h3>
                                <p className="mt-3">We implement a personalized approach aimed at mitigating symptoms, resolving underlying issues, and fostering long-term healing and growth.</p>
                            </div>
                            <div className="flex-c img-corrective-feat img-feat-img border-r-16-bottom" />
                        </div>
                    </div>
                    <div className="flex-c col-lg-4 mb-5">
                        <div className="flex-c bckg-240240245 border-r-16 mb-5 mx-lg-2">
                            <div className="flex-c px-4 pt-3 pb-4">
                                <div className="flex-r align-items-center justify-content-between mb-3">
                                    <span className="font-16 fw-700">Preventative</span>
                                    <span className="flex-c align-items-center justify-content-center circle-md bckg-215215225">
                                        <i className="fa-solid fa-shield-halved font-20"></i>
                                    </span>
                                </div>
                                <h3>Proactive Wellness</h3>
                                <p className="mt-3">We help in cultivating habits, practices, and mindsets that promote resilience, balance, and emotional well-being emphasizing prevention over reaction.</p>
                            </div>
                            <div className="flex-c img-preventative-feat img-feat-img border-r-16-bottom" />
                        </div>
                    </div>
                    <div className="flex-c col-lg-4 mb-5">
                        <div className="flex-c bckg-240240245 border-r-16 mb-5 ml-lg-2">
                            <div className="flex-c px-4 pt-3 pb-4">
                                <div className="flex-r align-items-center justify-content-between mb-3">
                                    <span className="font-16 fw-700">Collaborative</span>
                                    <span className="flex-c align-items-center justify-content-center circle-md bckg-215215225">
                                        <i className="fa-solid fa-user-group font-16"></i>
                                    </span>
                                </div>
                                <h3>Team-Based Support</h3>
                                <p className="mt-3">A collaborative approach to addressing mental health challenges, drawing on the expertise of your own mental health professionals and support team.</p>
                            </div>
                            <div className="flex-c img-collab-feat img-feat-img border-r-16-bottom" />
                        </div>
                    </div>
                </div>

                <div className="flex-c row">
                    <div className="flex-c align-items-lg-center col-12">
                        <h4 className="flex-c font-48 fw-700 mb-0 mt-0 mt-lg-5">Experience the difference.</h4>
                        <h2 className="flex-c h2-sub mt-2 mb-4">A common sense approach to mental health & wellness.</h2>
                    </div>
                </div>
                <div className="flex-c flex-lg-r row mt-5">
                    <div className="flex-c col-xl-4 mb-5">
                        <div className="flex-c bckg-secondary border-r-16 mb-5 mx-lg-2">
                            <div className="flex-c p-4">
                                <i className="fa-solid fa-brain white font-32 pt-1"></i>
                                <h5 className="font-32 fw-500 white m-0 mt-4">Psychology-driven, personalized mental wellness.</h5>
                                <p className="white lh-24 mt-3">Rooted in psychology, offering personalized, evidence-based, techniques & validated by mental health professionals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-c col-xl-4 mb-5">
                        <div className="flex-c bckg-secondary border-r-16 mb-5 mx-lg-2">
                            <div className="flex-c p-4">
                                <i className="fa-solid fa-gamepad white font-32 pt-1"></i>
                                <h5 className="font-32 fw-500 white m-0 mt-4">Level up your mental wellness journey.</h5>
                                <p className="white lh-24 mt-3">An engaging gamified experience using interactive challenges and rewards to improve your mental well-being.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-c col-xl-4 mb-5">
                        <div className="flex-c bckg-secondary border-r-16 mb-5 mx-lg-2">
                            <div className="flex-c p-4">
                                <i className="fa-solid fa-chart-line white font-32 pt-1"></i>
                                <h5 className="font-32 fw-500 white m-0 mt-4">Modern mental wellness with trackable results.</h5>
                                <p className="white lh-24 mt-3">The algorithmic based response system delivers measurable and meaningful results over varying degrees of time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-c bckg-240240245 py-5">
                <div className="flex-c container my-5">
                    <div className="flex-c flex-lg-r row my-lg-5">
                        <div className="flex-c col-lg-6 order-1 order-lg-0">
                            <div className="flex-c bckg-white border-r-16-bottom border-lg-r-16 mr-lg-3">
                                <div className="flex-c p-5">
                                    <span className="flex-c align-items-center justify-content-center circle-md bckg-240240245 mb-4">
                                        <i className="fa-solid fa-notes-medical font-20"></i>
                                    </span>
                                    <span className="font-16 fw-700 mb-2">Corrective</span>
                                    <h6 className="font-48 fw-300 m-0">Restorative Healing</h6>
                                    <p className="mt-4">Corrective mental wellness focuses on identifying and addressing existing mental health challenges through targeted interventions and strategies. By acknowledging and addressing specific concerns, whether they be anxiety, depression, trauma, or other conditions, corrective mental wellness empowers individuals to regain balance, resilience, and a sense of well-being.</p>
                                    <ul className="mt-3">
                                        <li>Emotional Resilience</li>
                                        <li>Restore Relationships</li>
                                        <li>Enhanced Self-Awareness</li>
                                        <li>Reduced Symptoms</li>
                                        <li>Greater Life Satisfaction</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex-c col-lg-6 order-0 order-lg-1">
                            <div className="flex-c border-r-16-top border-lg-r-16 img-corrective-full img-feat-full ml-lg-3" />
                        </div>
                    </div>
                    <div className="flex-c row my-lg-5" />
                    <div className="flex-c flex-lg-r row my-5">
                        <div className="flex-c col-lg-6">
                            <div className="flex-c border-r-16-top border-lg-r-16 img-preventative-full img-feat-full mr-lg-3" />
                        </div>
                        <div className="flex-c col-lg-6">
                            <div className="flex-c bckg-white border-r-16-bottom border-lg-r-16 ml-lg-3">
                                <div className="flex-c p-5">
                                    <span className="flex-c align-items-center justify-content-center circle-md bckg-240240245 mb-4">
                                        <i className="fa-solid fa-shield-halved font-20"></i>
                                    </span>
                                    <span className="font-16 fw-700 mb-2">Preventative</span>
                                    <h6 className="font-48 fw-300 m-0">Proactive Wellness</h6>
                                    <p className="mt-4">Proactive mental wellness is about prioritizing mental health before issues arise, emphasizing prevention rather than reaction. Through activities such as mindfulness, regular exercise, healthy nutrition, and stress management techniques, individuals equip themselves with the tools to manage life's challenges effectively, thrive in their daily lives and maintain optimal mental health long term.</p>
                                    <ul className="mt-3">
                                        <li>Stress Management</li>
                                        <li>Healthy Coping Mechanisms</li>
                                        <li>Positive Mindset</li>
                                        <li>Inner Fortitude</li>
                                        <li>Cognitive Empowerment</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-c row my-lg-5" />
                    <div className="flex-c flex-lg-r row my-5">
                        <div className="flex-c col-lg-6 order-1 order-lg-0">
                            <div className="flex-c bckg-white border-r-16-bottom border-lg-r-16 mr-lg-3">
                                <div className="flex-c p-5">
                                    <span className="flex-c align-items-center justify-content-center circle-md bckg-240240245 mb-4">
                                        <i className="fa-solid fa-user-group font-16"></i>
                                    </span>
                                    <span className="font-16 fw-700 mb-2">Collaborative</span>
                                    <h6 className="font-48 fw-300 m-0">Team-Based Support</h6>
                                    <p className="mt-4">Team-based mental wellness support involves a collaborative approach to addressing mental health challenges, drawing on the expertise of various professionals and support networks. It brings together therapists, counselors, psychiatrists, social workers, family members, and other caregivers to create a comprehensive support system tailored to the individual's needs.</p>
                                    <ul className="mt-3">
                                        <li>Collaborative Care</li>
                                        <li>Peer Support</li>
                                        <li>Shared Responsibility</li>
                                        <li>Mutual Encouragement</li>
                                        <li>Collective Resilience</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex-c col-lg-6 order-0 order-lg-1">
                            <div className="flex-c border-r-16-top border-lg-r-16 img-collab-full img-feat-full ml-lg-3" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-c py-5 bckg-white">
                <div className="flex-c container pt-5">
                    <div className="flex-c align-items-center text-c row mb-5">
                        <h4 className="font-48 m-0 p-0 mb-5">
                            Take charge of your mental well-being.
                        </h4>
                        <div className="flex-c flex-sm-r flex-auto col-12 col-md-7 col-lg-5 mt-2">
                            <Link
                                type="button"
                                className="btn btn-secondary mb-3 mb-sm-0 mr-sm-2"
                                title="Sign Up"
                                // to="/disclaimer"
                                to="/maintenance">
                                Get Started Free
                            </Link>
                            <Link
                                type="button"
                                className="btn btn-white ml-sm-2"
                                title="View Plans"
                                to="/pricing">
                                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
